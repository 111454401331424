import { useEffect } from "react";

import { useUserUid } from "./useUserUid";
import utils from "~/utils";

export const useAnalytics = () => {
  const userUid = useUserUid();

  useEffect(() => {
    utils.analytics.enableAnalytics(userUid);
  }, [userUid]);
};
